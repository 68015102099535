<!-- 首页-咨询评估-这样的样式 -->
<template>
  <div class="consult_trends">
    <div class="trends_title" v-if="isTitle">
      <span class="big_title">{{ bigTitle }}</span>
      <span class="title_more" @click="moreClick({ title: bigTitle, trendsType })">更多</span>
    </div>
    <div class="trends_main">
      <el-row>
        <el-col :xs="colSpan" :sm="colSpan" :lg="colSpan" style="flex-wrap: wrap;" v-for="i in list" :key="i.id">
          <div :class="{ main_content: true, line_border: isLine }" @click="titleClick(i)">
            <div class="main_img">
              <el-image style="height: 140px;width: 140px;" fit="cover" :alt="i[imgField][0].originalFileName"
                :src="i[imgField][0].address">
              </el-image>
            </div>
            <div class="main_title">
              <div class="main_small_title small_title ">
                <div class="css_title text_exceed_1row_ellipsis">
                  <!-- :style="{ width: isTime ? 'calc(100% - 110px)' : '100%' }" -->
                  {{ i.title }}
                </div>
                <div class="main_time font_content" v-if="isTime">
                  {{ i[timeField]?.split(' ')[0] ?? '1966-02-20' }}
                </div>
              </div>
              <div class="font_content text_exceed_4row_ellipsis"
                :style="{ 'text-indent': bigTitle === '加入我们' ? '0em' : '2em' }">
                {{ i.subtitle }}
              </div>
            </div>

          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script setup>
import { titleClickJumpPage } from '@/utils/titleClickJumpPage';
const props = defineProps({
  isTitle: {
    type: Boolean,
    default: true
  },
  isTime: {
    type: Boolean,
    default: false
  },
  bigTitle: {
    type: String,
    default: '大标题'
  },
  list: {
    required: true,
    type: Array,
    default: []
  },
  imgField: {
    type: String,
    default: 'cover'
  },
  timeField: {
    type: String,
    default: 'scheduledReleaseTime'
  },
  trendsType: {
    type: Number,
    default: 1
  },
  isLine: {
    type: Boolean,
    default: false
  },
  colSpan: {
    type: Number,
    default: 8
  }
})

// consultationTrendsItem
const titleClick = i => {
  // linksType 2.外链
  if (i.linksType === 2) {
    window.open(i.content)
  } else {
    titleClickJumpPage({ ...i, bigTitle: props.bigTitle }, 'ConsultationTrendsItem')
  }
}
const moreClick = data => {
  titleClickJumpPage(data, 'ConsultationTrendsMore')
}
</script>

<style scoped lang='scss'>
$moreColor: #027DB4;

.consult_trends {
  .trends_title {
    margin-top: 8px;
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    padding-bottom: 6px;
    border-bottom: 1.5px solid $moreColor;


    .title_more {
      font-size: 18px;
      color: $moreColor;
      cursor: pointer;
    }
  }



  .main_content {
    width: 100%;
    padding: 10px 0 0 0;
    display: flex;
    cursor: pointer;

    .main_img {
      width: 140px
    }

    .main_title {
      width: calc(100% - 156px);
      // width: calc(100% - 125px);
      padding: 0 10px;
      text-align: left;
      text-indent: 0em;
      letter-spacing: 0rem;

      .main_small_title {

        margin: 0 0 20px 0;
        padding-right: 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .css_title {
          flex: 1;
        }

        .main_time {
          font-size: 14px !important;
          // width: 93px !important;
          color: #7f7f7f;
        }
      }



      .font_content {
        font-size: 16px;
        line-height: 2em;
        -webkit-line-clamp: 3
      }
    }

    &:hover {
      // box-shadow: 0px 0px 22px #cbcbcb;
    }
  }

  .line_border {
    // border-top: 1.5px solid $moreColor;
    border-bottom: 1.5px solid $moreColor;
    padding-bottom: 20px;
  }
}

:deep .el-row .el-col:first-child .line_border {
  border-top: 1.5px solid $moreColor;
}
</style>