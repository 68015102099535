import { render, staticRenderFns } from "./index.vue?vue&type=template&id=87b649e2&scoped=true"
import script from "./index.vue?vue&type=script&setup=true&lang=js"
export * from "./index.vue?vue&type=script&setup=true&lang=js"
import style0 from "./index.vue?vue&type=style&index=0&id=87b649e2&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/.pnpm/vue-loader@15.11.1_lnntcuja7hkt3uqtlykgofep2u/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "87b649e2",
  null
  
)

export default component.exports