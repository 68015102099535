<!-- 图片平滑轮播 -->
<template>
  <!-- :style="isButton && list.length > number && showArrow ? 'padding:0 40px 0 40px;' : ''" -->
  <div class="smooth-carousel" @mouseenter="handleEnterArrow" @mouseleave="handleLeaveArrow">
    <div :class="'swiper-container' + ' ' + className">
      <!-- 图片轮播样式 -->
      <div class="swiper-wrapper">
        <div class="swiper-slide swiper-item" v-for="(i, index) in list" :key="index">
          <!-- @click=" handleImgClick(i) " -->
          <img class="img" :style="{ height: imgHeight, cursor: isPreview ? 'pointer' : '', width: imgWidth }"
            :src="i[imgField] ? i[imgField][0].address : i.address" alt="">
          <div class="img_num" :style="{ bottom: isTitle ? '60px' : '16px' }" v-if="isNum && i[imgField]?.length">
            <span>{{ i[imgField]?.length || '' }}</span>
            <i class="el-icon-picture-outline"></i>
          </div>
          <div class="title text_exceed_1row_ellipsis" v-if="isTitle">{{ i[titleField] }}</div>
        </div>
      </div>
      <!-- 如果需要分页器 -->
      <div class="swiper-pagination" v-if="isPage"></div>

    </div>
    <!-- 左右箭头 -->
    <div :class="'swiper-button-prev1' + ' ' + className + '-prev'"
      v-show="isButton && list.length > number && showArrow">
      <i class="el-icon-arrow-left"></i>
    </div>
    <div :class="'swiper-button-next1' + ' ' + className + '-next'"
      v-show="isButton && list.length > number && showArrow">
      <i class="el-icon-arrow-right"></i>
    </div>
    <!-- 这是组件自带的 大图预览 -->
    <el-image ref="elImageRef" v-show="false" class="img" :src="imgUrl" :preview-src-list="imgList">
    </el-image>
    <!-- 这是自写的大图预览 -->
    <ac-image-preview :visible.sync="visible" v-if="visible" :list="imgList" />
  </div>
</template>

<script>
/* 引入swiper插件 */
import Swiper from "swiper";

export default {
  props: {
    className: {
      required: true,
      type: String,
      default: 'swiper-container'
    },
    list: {
      type: Array,
      default: []
    },
    imgField: {
      type: String,
      default: 'atlas'
    },
    isTitle: {
      type: Boolean,
      default: true
    },
    isNum: {
      type: Boolean,
      default: true
    },
    titleField: {
      type: String,
      default: 'atlasTitle'
    },
    loop: {
      type: Boolean,
      default: true
    },
    number: {
      type: Number,
      default: 4
    },
    isBreakpoints: {
      type: Boolean,
      default: false
    },
    spaceBetween: {
      type: Number,
      default: 10,
    },
    speed: {
      type: Number,
      default: 5000
    },
    direction: {
      type: String,
      default: "horizontal"
    },
    pagination: {
      type: Boolean,
      default: true
    },
    isPreview: {
      type: Boolean,
      default: true
    },
    autoplay: {
      default: () => ({
        delay: 0, // 每循环一圈，间隔时间，无缝轮询，则为0
        disableOnInteraction: false, // 启用用户操作（如拖动、点击）后轮播仍然继续。
        stopOnLastSlide: false, // 让轮播循环播放
      })
    },
    isPage: {
      type: Boolean,
      default: false
    },
    isButton: {
      type: Boolean,
      default: false
    },
    imgHeight: {
      type: String,
      default: "300px"
    },
    imgWidth: {
      type: String,
      default: "100%"
    }
  },
  data() {
    return {
      imgUrl: '',
      imgList: [],
      visible: false,
      showArrow: false,
    }
  },
  mounted() {
    this.bannerPoint()
  },
  methods: {
    handleEnterArrow() {
      this.showArrow = true
    },
    handleLeaveArrow() {
      this.showArrow = false
    },
    bannerPoint() {
      new Swiper(`.${this.className}`, {
        observer: true,
        observeSlideChildren: true,
        observeParents: true,  //这三个可以去文档看下介绍，主要就是异步情况会重新加载swiper。
        loop: this.loop,
        loopedSlides: 20,
        on: { // 处理loop为true后 图片点击失效
          click: (event) => {
            if (this.isPreview) {
              let src = decodeURI(event.target.src)
              if (event.target.src) {
                const data = this.list.find(i =>
                  i[this.imgField][0].address === src
                )
                this.handleImgClick(data)
              }
            }
          }
        },
        speed: this.speed,// 每个轮播图过渡的时间
        spaceBetween: this.spaceBetween, // 轮播图间隔
        autoplay: this.autoplay,
        updateOnImagesReady: true,
        pdateOnWindowResize: false,  //分辨率改变时，防止样式错乱加上！
        slidesPerView: this.number,  //设置slider容器能够同时显示的slides数量
        direction: this.direction,
        autoplayDisableOnInteraction: false,// --在点击之后可以继续实现轮播
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
          paginationClickable: true,
        },
        // 如果需要前进后退按钮
        navigation: {
          nextEl: `.${this.className}-next`, // 右按钮
          prevEl: `.${this.className}-prev`, // 左按钮
        },

        breakpoints: this.isBreakpoints ? {
          //当宽度小于等于
          1100: {
            slidesPerView: 3, // 设置slider容器能够同时显示的slides数量.可以设置为数字（可为小数，小数不可loop），或者 'auto'则自动根据slides的宽度来设定数量。
            spaceBetween: 10 // slide之间的距离（单位px）。
          },
          1400: {
            slidesPerView: 4, // 设置slider容器能够同时显示的slides数量.可以设置为数字（可为小数，小数不可loop），或者 'auto'则自动根据slides的宽度来设定数量。
            spaceBetween: 10 // slide之间的距离（单位px）。
          },
          1600: {
            slidesPerView: 5, // 设置slider容器能够同时显示的slides数量.可以设置为数字（可为小数，小数不可loop），或者 'auto'则自动根据slides的宽度来设定数量。
            spaceBetween: 10 // slide之间的距离（单位px）。
          },
          1800: {
            slidesPerView: 6,
            spaceBetween: 10
          },
          2200: {
            slidesPerView: 7,
            spaceBetween: 10
          },
          2600: {
            slidesPerView: 10,
            spaceBetween: 10
          },
          3000: {
            slidesPerView: 7,
            spaceBetween: 10
          }
        } : null
      }
      )
    },
    handleImgClick(item) {
      console.log(this.list);
      if (this.isPreview) {
        this.imgList = item[this.imgField].map(i => i.address)
        this.showArrow = false
        // 这个是el-images组件带的大图预览 this.$refs.elImageRef.showViewer = true
        this.visible = true
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.smooth-carousel {
  position: relative;
  width: 100%;
  overflow: hidden;
  box-sizing: border-box;


  .swiper-container {
    width: 100%;
    overflow: hidden;
    background-color: #fff !important;


    .swiper-wrapper {

      transition-timing-function: linear !important; //想好具体位置放到哪，得能替换！

    }

    .swiper-item {
      overflow: hidden;
      position: relative;
      width: 100%;
      height: 100%;
      text-align: center;

      .img {
        width: 100%;
        height: auto;

      }

      .img_num {
        position: absolute;
        right: 0;
        bottom: 20px;
        z-index: 333;
        padding: 0 5px;
        height: 24px;
        background-color: rgba(0, 0, 0, .5);
        color: #fff;
        display: flex;
        align-items: center;
        font-size: 14px;
        font-weight: normal;
        justify-content: space-between;

        span {
          margin-right: 5px;
        }

        i {
          font-size: 16px;
        }
      }

      .title {
        margin: 10px 0 20px;
        text-align: center;
        font-family: var(--font-primary);
        font-weight: 700;
        font-style: normal;
        text-align: center;
        color: #333333;
        font-size: 18px;
      }
    }

  }

  .swiper-button-prev1,
  .swiper-button-next1 {

    width: 30px;
    background-color: #fff;
    position: absolute;
    top: 0;
    bottom: 24px;
    z-index: 1;
    display: flex;
    align-items: center;
    font-size: 30px;
    color: $organizationBgc;
    cursor: pointer;
  }

  .swiper-button-prev1 {
    left: 0px;
  }

  .swiper-button-next1 {
    right: 0;
  }
}
</style>